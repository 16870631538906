<template>
  <div v-if="classe && classe.educatori && classe.educatori.length > 0 && !isClasse">

    <div class="chattoggle" @click="toggleMessaggi">
        <img src="@/assets/imgs/mascotte-tipo1.svg" v-if="classe.percorso.categoria == 'tipo1'"/>
        <img src="@/assets/imgs/mascotte-tipo2.svg" v-if="classe.percorso.categoria == 'tipo2'"/>
        <img src="@/assets/imgs/mascotte-tipo3.svg" v-if="classe.percorso.categoria == 'tipo3'"/>
         <!--<img src="@/assets/imgs/chat.svg"/>-->
         <span v-if="isInsegnante" class="chattoggle_label">Scrivi <br/>al tutor</span><span class="chattoggle_label" v-else>Scrivi <br/>all'insegnante</span>
          <div class="unreaded" v-if="getMessaggiNonLetti.length > 0">{{ getMessaggiNonLetti.length }}</div>
    </div>


    <UiModal :modal="modalShow" :modalShow.sync="modalShow">
          <template v-slot:header>
              <h4 class="modal--title"><span v-if="isInsegnante">Scrivi al tutor</span><span v-else>Scrivi all'insegnante</span></h4>
          </template>

          <template v-slot:form>
              <div class="form">
                  <div class="chat">
                      <div class="chat--messages" id="containerMessaggi">
                          <template v-if="getMessaggi && getMessaggi && getMessaggi.length > 0">
                              <div v-for="messaggio in getMessaggi" :key="messaggio.id">
                                <div class="message" v-if="messaggio.mittente">
                                    <div class="text" :class="{ 'my' : messaggio.mittente.id == user.user.id}" v-view="viewHandler" :data-id="messaggio.id" :data-readed="messaggio.letto" :data-mittente="messaggio.mittente.id">
                                      <div class="message-header">
                                      <strong>{{ messaggio.mittente.nome }}</strong>
                                      <span> {{ getHumanDate(messaggio.created_at) }}</span>
                                      </div>
                                      {{ messaggio.messaggio }}
                                      <UiDropdown :dropdown="false" v-if="messaggio.mittente.id == user.user.id" :dropdownClass="'edit dropdown-right dropdown-small'">
                                            <template v-slot:toggle>
                                              <svg class="icon" width="12" height="12"><use xlink:href="#edit" /></svg>
                                            </template>
                                            <template v-slot:content>
                                              <span @click="eliminaMessaggio(messaggio)" class="item">Elimina</span>
                                            </template>
                                      </UiDropdown>
                                      <span class="nonletto" v-if="messaggio.letto == 0 && messaggio.mittente.id != user.user.id"></span>
                                    </div>
                                  </div>
                              </div>
                          </template>
                      </div>
                      
                  </div>

                  <div class="field-group flex" v-if="!isClasse">
                      <div class="field col col-8">
                        <input type="text" v-model="messaggio" ref="messaggio">
                      </div>
                      <div class="field col col-3">
                        <button class="button" @click.prevent="createMessaggio" :disabled="loading || messaggio == ''">Invia</button>     
                      </div>
                  </div>

              </div>
                
        </template>
      </UiModal>

    </div>
  
</template>


<script>
import moment from 'moment'
import { mapGetters, mapState, mapActions } from 'vuex'


export default {
  name: 'Chat',
  data () {
    return {
      messaggio: '',
      focus: true,
      modalShow: false,
      loading: false,
      timer_nuovimessaggi: null,
      timer_letture: null,
      ids: [],
    }
  },

  props: {
    classe: [Object, Array]
  },

  components: {
  },

  watch: {

  // autofocus sull'input
  focus(val){
      if (val == true){
          this.$nextTick(() => {
              this.$refs.messaggio.focus()
          })
      }
    }
  },

  modalShow(val) {
    if (val == true) {
       this.goToEnd()
    }
  },

  computed: {
     ...mapGetters('messaggi',[
        'getMessaggi',
        'getMessaggiNonLetti',
     ]),

     ...mapGetters([
      'user',
      'isClasse',
      'isEducatore',
      'isInsegnante'
    ]),

  },


  methods: {
    ...mapActions('messaggi',[
        'addMessaggio',
        'updateMessaggio',
        'deleteMessaggio'
    ]),

    toggleMessaggi() {
        this.modalShow = true
    },

    viewHandler(e) {
      if(!this.isClasse) {
        const type = e.type
        const id = e.target.element.attributes['data-id'].nodeValue
        const mittente = e.target.element.attributes['data-mittente'].nodeValue
        const letto = e.target.element.attributes['data-readed'].nodeValue
        const user = this.user.user.id

        if('enter' == type && letto == 0 &&  mittente != user) {
          //this.segnaLetti(id) 
          this.ids.push(id)
        }
     }
    },

    segnaLetti() {
      if(this.ids.length > 0 && !this.isClasse) {
          this.ids.forEach((id, i) => {
            setTimeout(() => {
                this.updateMessaggio({
                id:  id
              }).then((response) => {
                this.ids.splice(this.ids.indexOf(id), 1)
              })
            }, i * 1000);
          });
      }
    },

    createMessaggio () {
      this.loading = true
      this.addMessaggio({
          messaggio: this.messaggio,
          classe_id: this.classe.id
      }).then((response) => {
         this.messaggio = ''
         this.loading = false
         this.$store.dispatch('messaggi/fetchMessaggiFromClasse', {
            params: {
              classe_id: this.$route.params.classeId
            }
        })
        this.goToEnd()
      }).catch(e => {
            console.log(e)
            this.$store.state.success = null;
            this.$store.state.errors = e.response.data;
        })
    },

    goToEnd() {
      var container = this.$el.querySelector("#containerMessaggi");
      container.scrollTop = container.scrollHeight;
    },

    checkNewMessages () {
      this.$store.dispatch('messaggi/fetchMessaggiFromClasse', {
          params: {
             classe_id: this.$route.params.classeId
          }
      })
      this.segnaLetti()
     },

    getHumanDate : function (date) {
      return moment(date).locale('it').calendar();
     },

     eliminaMessaggio (messaggio) {
            this.loading = true
            this.deleteMessaggio(messaggio).then((response) => {
                this.loading = false
                this.$store.dispatch('messaggi/fetchMessaggiFromClasse',{
                    params: {
                      classe_id: this.$route.params.classeId
                    }
                })
                this.goToEnd()
            }).catch(error => {
                console.log('Errore:'+error)
            })
        },


  },

  beforeMount() {
    
  },

  mounted() {
    this.$store.dispatch('messaggi/fetchMessaggiFromClasse', {
        params: {
          classe_id: this.$route.params.classeId
        }
    })

    this.timer_nuovimessaggi = window.setInterval(() => {
        this.checkNewMessages()
       // this.segnaLetti()
    }, 10000)
  /*
    this.timer_letture = window.setInterval(() => {
        this.segnaLetti()
    }, 10000)
    */

  },
  
  destroyed () {
    clearInterval(this.timer_nuovimessaggi)
    //clearInterval(this.timer_letture)
  }

}
</script>