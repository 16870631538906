<template>
  <div v-if="getClasse">
      <div class="scuola" v-if="getClasse.nome && getClasse.scuola">{{  getClasse.nome }} {{  getClasse.scuola.nome }} {{  getClasse.scuola.comune }}</div>
      <Percorso v-if="getClasse.percorso && !getClasse.quiz" :classe="getClasse" :percorso="getClasse.percorso"/>
      <Quiz v-else :classe="getClasse"/>
  </div>
  <div v-else>
    <Loading />
  </div>
</template>

<script>
// @ is an alias to /src
import Percorso from '@/components/Percorso.vue'
import Quiz from '@/components/Quiz.vue'
import Loading from '@/components/Loading.vue'

import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  name: 'Classe',

  data: () => {
    return {
      toggleMenu: false,
      timer_classe: ''
    }
  },
  components: {
    Percorso,
    Quiz,
    Loading
  },

  watch: {
      '$route.params': {
          handler(newValue) {
              const { classeId } = newValue
              this.$store.dispatch('classi/fetchClasse', {
                id: classeId
              })
          },
          immediate: true,
      }

  },

  computed: {
     ...mapGetters('classi',[
        'getClasse',
        'getPercorso'
     ]),

     classeIdFromParams () {
        return this.$route.params.classeId
     },
  },

  methods: {
      ...mapActions('classi',[
        'fetchClasse',
        'destroyClasse'
      ]),

      checkQuizMode () {
        this.$store.dispatch('classi/fetchClasse', {
           id: this.$route.params.classeId
        })
      }
  },

  beforeCreate() {
    this.$nextTick().then(() => document.body.className = '')
  },

  beforeMount: function() {
      this.$store.dispatch('classi/fetchClasse', {
           id: this.$route.params.classeId
      })
  },
  mounted: function () {
     this.timer_classe =  window.setInterval(() => {
        this.checkQuizMode()
      }, 20000) 
  },

  destroyed: function() {
    clearInterval(this.timer_classe)
    this.$store.dispatch('classi/destroyClasse')
  }


}
</script>
