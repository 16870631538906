<template>
  <div class="quizPage">
  <main v-if="classe && classe.percorso && classe.percorso.test">

    <div class="questionBox">


	  <!-- transition -->
		<transition :duration="{ enter: 500, leave: 300 }" enter-active-class="animated zoomIn" leave-active-class="animated zoomOut" mode="out-in">

			<!--qusetionContainer-->
			<div class="questionContainer" v-if="questionIndex<quiz.test.length" :key="questionIndex">

				<header>
					<h1 class="title is-6">{{ classe.percorso.test.nome}} </h1>
					<!--progress-->
					<div class="progressContainer">
						<progress class="progress is-info is-small" :value="(questionIndex/quiz.test.length)*100" max="100">{{(questionIndex/quiz.test.length)*100}}%</progress>
						<!--<p>{{(questionIndex/quiz.test.length)*100}}% complete</p>-->
					</div>
					<!--/progress-->
				</header>

				<!-- questionTitle -->
				<h2 class="titleContainer title">{{ quiz.test[questionIndex].domanda }}</h2>

				<!-- quizOptions -->
				<div class="optionContainer">
					<div class="option" v-for="(response, index) in quiz.test[questionIndex].risposte" @click="selectOption(index)" :class="{ 'is-selected': userResponses[questionIndex] == index}" :key="index">
						{{ index | charIndex }}. {{ response.text }}
					</div>
				</div>

				<!--quizFooter: navigation and progress-->
				<footer class="questionFooter">

					<!--pagination-->
					<nav class="pagination" role="navigation" aria-label="pagination">

						<!-- back button -->
						<a class="button" v-on:click="prev();" v-if="questionIndex >= 1">
                Indietro
            </a>

						<!-- next button -->

           
						

            <button class="button button-right" v-if="questionIndex >= (quiz.test.length - 1)" @click="invioRisultato(score(), quiz.test.length)">
              Avanti
            </button>
            <a v-else class="button button-right" :class="(userResponses[questionIndex]==null)?'':'is-active'" v-on:click="next();" :disabled="questionIndex>=quiz.test.length">
                    {{ (userResponses[questionIndex]==null)?'Salta':'Avanti' }}
            </a>


					</nav>
					<!--/pagination-->

				</footer>
				<!--/quizFooter-->

			</div>
			<!--/questionContainer-->

			<!--quizCompletedResult-->
			<div v-if="questionIndex >= quiz.test.length" v-bind:key="questionIndex" class="quizCompleted has-text-centered">

				<!-- quizCompletedIcon: Achievement Icon -->

        <div class="risultato" v-if="score()<= 5">
              <img src="@/assets/imgs/risultato1.svg"/>
              <h2 class="title">Puoi fare meglio!</h2>
              <p class="subtitle">L’ambiente ha bisogno anche di te! <br/>Riguarda le lezioni e riprova</p>
              <p>{{ score() }} risposte esatte su {{ quiz.test.length }}</p>
        </div>
        <div class="risultato" v-else-if="score()> 5 && score() < 9">
              <img src="@/assets/imgs/risultato2.svg"/>
              <h2 class="title">Bene!</h2>
              <p class="subtitle">Hai imparato tante cose… <br/>ma puoi fare ancora meglio</p>
              <p>{{ score() }} risposte esatte su {{ quiz.test.length }}</p>
        </div>
        <div class="risultato" v-else-if="score()>= 9">
              <img src="@/assets/imgs/risultato3.svg"/>
              <h2 class="title">Eccezionale!!</h2>
              <p>Congratulazioni! <br/>L'ambiente ti ringrazia</p>
              <p>{{ score() }} risposte esatte su {{ quiz.test.length }}</p>
        </div>

        <br>
        <a class="button" @click="restart()">Ricomincia <i class="fa fa-refresh"></i></a>
				<!--/resultTitleBlock-->

			</div>
			<!--/quizCompetedResult-->

		</transition>

	</div>


  </main>
  </div>
  
</template>


<script>
import router from '@/router'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Quiz',
  data () {
    return {
      questionIndex: 0,
      userResponses: Array(this.length).fill(null)
    }
  },

  filters: {
      charIndex: function(i) {
         return String.fromCharCode(97 + i);
      }
   },

  props: {
    classe: [Object, Array]
  },

  computed: {


    quiz() {
      return this.classe.percorso.test
    },

    length() {
      return this.quiz.test.length
    },

    ...mapGetters([
      'isClasse'
    ]),
  

  },

  methods: {

    ...mapActions('classi',[
        'salvaRisultatoQuiz',
    ]),


    restart: function(){
			 	this.questionIndex=0;
		 		this.userResponses=Array(this.quiz.test.length).fill(null);
     },
     
      selectOption: function(index) {
       this.$set(this.userResponses, this.questionIndex, index);
      },

      next: function() {
         if (this.questionIndex < this.quiz.test.length)
            this.questionIndex++;
      },

      prev: function() {
         if (this.quiz.test.length > 0) this.questionIndex--;
      },
      // Return "true" count in userResponses
      score: function() {
         var score = 0;
         for (let i = 0; i < this.userResponses.length; i++) {
            if (
               typeof this.quiz.test[i].risposte[
                  this.userResponses[i]
               ] !== "undefined" &&
               (this.quiz.test[i].risposte[this.userResponses[i]].correct || this.quiz.test[i].risposte[this.userResponses[i]].correct == 'true')
            ) {
               score = score + 1;
            }
         }
         return score;

      },

      invioRisultato: function(score, domande) {
        if(!this.isClasse) {
            this.next()
        } else {
            this.salvaRisultatoQuiz({
                test_id: this.classe.percorso.test.id,
                classe_id: this.classe.id,
                punteggio: score,
                numero_domande: domande
            }).then((response) => {
                this.next()
            }).catch(e => {
                this.next()
            })
        }
        
      }
       


  },


  beforeMount() {
    
  },

  mounted() {

  },
  
  beforeDestroy() { 
 
  },

}
</script>