<template>  
    <div v-if="lezione">
        <div>{{ lezione.titolo}} <span class="badge badge--ligthgray" v-if="lezione.published == 0" @click="!submenu">BOZZA</span></div>
            <ul v-if="lezione.risorse && lezione.risorse.length > 0 && lezione.id == $route.params.lezioneId && $route.name == 'Risorsa' || submenu">
                <li v-for="risorsa in lezione.risorse" :key="risorsa.id">
                    <router-link :to="{ name: 'Risorsa' , params: {classeId: $route.params.classeId, lezioneId: lezione.id, risorsaId: risorsa.id }}">
                    <span v-if="risorsa.tipo == 'allegato'"><svg class="icon" width="16" height="16"><use xlink:href="#attachment" /></svg></span> <span v-if="risorsa.tipo == 'video'"><svg class="icon" width="16" height="16"><use xlink:href="#video" /></svg></span>
                    {{ risorsa.nome }}</router-link>
                </li>
            </ul>
    </div>
</template>

<script>



export default {
    name : 'LezioneMenu',

    data () {
        return {
            submenu : false
        }
    },

    props: {
        lezione: Object | Array
    },


}
</script>