<template>
  <div class="percorsoPage">
    <aside id="AppMenuPercorso">
        <div v-if="percorso">
          <div id="percorsoPage--header">

            <div id="titolo">
              <router-link v-if="percorso.titolo" :to="{ name: 'Classe' , params: {classeId: classe.id}}" tag="h1">
                {{ percorso.titolo }}
              </router-link>
              <h2 v-if="percorso.sottotitolo">{{ percorso.sottotitolo }}</h2>
              
            </div>
            <button @click="toggleMenu" id="toggleMenu" class="button--icon">
              Menu
            </button>
          </div>

          <div id="menu" :class="{'opened' : menu}">
            <ul v-if="lezioni && lezioni.length > 0">
                <li v-for="lezione in lezioni" :key="lezione.id"> 
                      <router-link :to="{ name: 'Lezione' , params: {classeId: classe.id, lezioneId: lezione.id }}" :class="{ current: lezione.id ==  $route.params.lezioneId, noresourse : lezione.risorse.length == 0 }" v-if="!isClasse || (isClasse && lezione.visibile_classe)">
                        <span>{{ lezione.titolo}}</span>
                        <span class="counter badge" v-if="lezione.risorse.length > 0">{{ lezione.risorse.length }} </span> <span class="badge badge--small badge--dark" v-if="lezione.published == 0">BOZZA</span>
                      </router-link>
                        <div v-if="3 == 4">
                          <ul v-if="lezione.risorse && lezione.risorse.length > 0 && lezione.id == $route.params.lezioneId">
                            <li v-for="risorsa in lezione.risorse" :key="risorsa.id">
                                <router-link :to="{ name: 'Risorsa' , params: {classeId: classe.id, lezioneId: lezione.id, risorsaId: risorsa.id }}">
                                  <svg class="icon" width="16" height="16" v-if="risorsa.tipo == 'allegato'"><use xlink:href="#attachment" /></svg>
                                  <svg class="icon" width="16" height="16" v-if="risorsa.tipo == 'video'"><use xlink:href="#video" /></svg>
                                  <span>{{ risorsa.nome }}</span></router-link>
                            </li>
                          </ul>
                        </div>
                </li>
            </ul>

            <small v-if="percorso.credits" class="credits"><strong>{{ percorso.credits }}</strong></small>

            <hr/>
            <ul v-if="getLezioniGenerali && getLezioniGenerali.length > 0">
                <li v-for="lezione in getLezioniGenerali" :key="lezione.id">
                      <router-link :to="{ name: 'Lezione' , params: {classeId: classe.id, lezioneId: lezione.id }}" :class="{ current: lezione.id ==  $route.params.lezioneId }" v-if="(lezione.published == 1 && lezione.visibile_classe) || isAdmin || (lezione.published == 1 && isEducatore) || (lezione.published == 1 && isInsegnante)">{{ lezione.titolo}} <span class="badge badge--small badge--dark" v-if="lezione.published == 0">BOZZA</span></router-link>
                        <ul v-if="lezione.risorse && lezione.risorse.length > 0 && lezione.id == $route.params.lezioneId && $route.name == 'Risorsa'">
                          <li v-for="risorsa in lezione.risorse" :key="risorsa.id">
                              <router-link :to="{ name: 'Risorsa' , params: {classeId: classe.id, lezioneId: lezione.id, risorsaId: risorsa.id }}" v-if="risorsa.published == 1 || isAdmin || (risorsa.published == 1 && isEducatore) || (risorsa.published == 1 && isInsegnante)">
                                <span v-if="risorsa.tipo == 'allegato'"><svg class="icon" width="16" height="16"><use xlink:href="#attachment" /></svg></span> <span v-if="risorsa.tipo == 'video'"><svg class="icon" width="16" height="16"><use xlink:href="#video" /></svg></span>
                                {{ risorsa.nome }}</router-link>
                          </li>
                    </ul>
                   
                </li>
            </ul>
            <small class="credits"><strong>I materiali sono stati realizzati in collaborazione con l’Ufficio Comunicazione Sei Toscana</strong></small>

          </div>

        </div>
    </aside>
  <main>

    <div class="iframe-container" v-if="$route.name == 'Classe'">
          <div class="iframe-container-header"></div>
          <iframe frameborder="0" id="vid" class="iframevideo" allowfullscreen
                  src="https://www.youtube-nocookie.com/embed/nOvyc6GXwjo?=showinfo=0&modestbranding=1&autohide=2&showsearch=0&cc_load_policiy=3&rel=0">
          </iframe>                             
    </div>
     <div class="iframe-container" v-if="$route.name == 'Classe'">
          <div class="iframe-container-header"></div>
          <iframe frameborder="0" id="vid" class="iframevideo" allowfullscreen
                  src="https://www.youtube-nocookie.com/embed/pO5GOBxzox8?=showinfo=0&modestbranding=1&autohide=2&showsearch=0&cc_load_policiy=3&rel=0">
          </iframe>                             
    </div>
    

    <router-view/>
    <Chat :classe="classe"/>

    
  </main>
  </div>
  
</template>


<script>

import Chat from '@/components/Chat.vue'
import LezioneMenu from '@/components/LezioneMenu.vue' // non usata
import router from '@/router'
import { mapGetters, mapActions } from 'vuex'


export default {
  name: 'Percorso',
  data () {
    return {
      menu: false,
      windowWidth: window.innerWidth
    }
  },

  props: {
    classe: Object,
    percorso: Object
  },

  components: {
    Chat,
    LezioneMenu
  },

  computed: {

    ...mapGetters([
      'isAdmin',
      'isClasse',
      'isEducatore',
      'isInsegnante'
    ]),


    ...mapGetters('lezioni',[
        'getLezioniGenerali'
     ]),


  
    lezioni: function() {

      if(this.isAdmin) {
         return this.percorso.lezioni.filter(lezione => lezione.percorso != null)
      } else {
          return this.percorso.lezioni.filter(lezione => lezione.published == 1 && lezione.percorso != null)
      }

    }


  },

  methods: {

      toggleMenu() {
          this.menu = !this.menu
      },
      /*
      menuVisibility() {
        this.windowWidth = window.innerWidth
        if(this.windowWidth <= 940) {
                this.menu = false
            } else {
                this.menu = true
        }
      },
      */

      ...mapActions('lezioni',[
        'fetchLezioni',
      ])
      

  },

  beforeMount() {
    this.$store.dispatch('lezioni/fetchLezioni')
  },
  /*
  mounted() {
      this.menuVisibility()
      this.$nextTick(() => {
         window.addEventListener('resize', this.menuVisibility );
         window.addEventListener('load', this.menuVisibility );
      })
  },
  
  beforeDestroy() { 
        window.removeEventListener('resize', this.menuVisibility ); 
        window.removeEventListener('load', this.menuVisibility );
  },

  */
  destroyed() {
    
  }

}
</script>